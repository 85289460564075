@import 'fonts';


/* COLORS ************************************/
:root {
    --red: #aa001e;
    --grey: #dbe1e9;
    --darkgrey: #242424;
    --beige: rgba(240, 225, 200);
}

/* SLIDER COLORS */

.red {
    background: rgba(170, 0, 30) !important;
    color: white !important;
}

.red-light {
    background: rgba(170, 0, 30, 0.75) !important;
    color: white;
}

.red button:hover,
.red-light button:hover {
    color: rgba(170, 0, 30) !important;
    background-color: white !important;
}

.beige {
    background-color: rgba(240, 225, 200) !important;
    color: black !important;
}

.beige-light {
    background-color: rgba(240, 225, 200, 0.75) !important;
    color: black !important;
}


.beige-light button:hover {
    color: rgba(240, 225, 200) !important;
    background-color: black !important;
}

.white {
    background: rgba(255, 255, 255, 0.75) !important;
    color: black !important;
}

.white button:hover {
    color: white !important;
    background-color: rgba(0, 0, 0) !important;
}

.black {
    background: rgba(0, 0, 0, 0.75) !important
}

.darkgrey {
    background: var(--darkgrey);
    color: white;
}

.grey {
    background: var(--grey);
    color: black;
}

/* MAIN **********************************************************************/

h1,
h2,
h3,
h4,
ul,
p,
.slate-editor,
.accordion-block,
.block.listing,
#page-search {
    max-width: var(--layout-container-width) !important;
}

//

@media screen and (max-width: 1490px) {

    h1,
    h2,
    h3,
    #page-document>.column-blocks-wrapper>.slate,
    #page-document>.blocks-group-wrapper>.slate,
    .newspage .slate,
    .accordion-block,
    .HomepageNewsKacheln {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}

#page-search {
    width: 100% !important;
}

.header-wrapper .header .search-wrapper .search .search-bar.active {
    top: 2rem;
    height: 10rem;
}

#page-document .accordion-block {
    margin-bottom: 4rem;
}

#page-document .accordion-block span {
    font-family: var("Inter", sans-serif) !important;
}

.blocks-group-wrapper {
    padding-bottom: 0 !important;
}

#page-document .accordion-block .blocks-group-wrapper {
    margin-bottom: 2rem !important;
}


h1 {
    margin-top: 1rem !important;
    margin-bottom: 3rem !important;
}

h2 {
    margin-bottom: 2rem !important;
}

.slate>h2 {
    margin-top: 2rem !important;
}

.heading-wrapper h2 {
    margin-top: 1rem !important;
}

h3 {
    margin-bottom: 1rem !important;
}

h4 {
    margin-bottom: 1rem;
}

h1,
h2,
#footer h4,
#navigation a,
.logo_text a,
.pdf-list h4 {
    font-family: 'PT Serif Caption', serif;
    font-style: normal;
    font-weight: 400;
}

#page-document .accordion-block span {
    font-family: 'PT Serif Caption', serif;
    //font-family: var(--custom-main-font, "Inter", sans-serif);
    font-style: normal;
    color: black !important;
    // font-weight: bold;
}

#page-document .accordion-block p {
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

p {
    hyphens: manual !important;
}

/* HEADER ***********************************************************************************/

.heading-wrapper {
    margin: 0 !important;
}

.breadcrumbs {
    display: none;
}

.logo {
    display: flex;
    padding: 1rem 0;
    flex: none !important;
}

.logo_text {
    justify-self: start;
    align-self: center;
    padding: 0 0.5rem;
}

.logo_text a {
    color: var(--red) !important;
    font-weight: bold;
}

.logo .image {
    width: 350px;
}

@media screen and (max-width: 767px) {
    .logo .image {
        width: 280px;
    }
}

@media screen and (max-width: 424px) {
    .logo .image {
        width: 200px;
    }
}

// @media screen and (min-width: 941px) and (max-width: 1079px) {
//     .logo .image {
//         width: 250px;
//     }
// }

.search-wrapper,
#navigation {
    flex: none !important;
}

#navigation {
    margin-left: auto;
}

.header-wrapper .header .logo-nav-wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

@media screen and (max-width: 1490px) {
    .header-wrapper .header .logo-nav-wrapper {
        padding: 3rem 1rem 3rem 1rem;
    }

}

.sticky-try {
    position: absolute;
    width: 100vw;
    min-height: 100vh;
}

.tools-wrapper {
    padding: 0.5rem 0;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
}

.tools-bar {
    display: flex;
    position: relative;
}

.tools {
    margin-left: auto;
    display: flex;
    align-items: center;
}

.tools a {
    padding: 0 1rem;
    color: #000;
    font-size: 14px;
    text-align: center;
}

.tools a:not(:last-child),
.tools .anontools {
    border-right: 1px solid black;
}

.header-wrapper .header .search-wrapper {
    align-self: center;
}

.navigation .ui.secondary.pointing.menu .item {
    padding: 1rem 0 1rem !important;
}

.item.active {
    border-color: var(--red) !important;
}

.header {
    margin: 0 !important;
}

.header-wrapper .header .search-wrapper .search .search-bar,
.header-wrapper .header .search-wrapper .search .search-bar input {
    background-color: var(--grey) !important;
}

// Mobile

.mobile-nav {
    display: flex;
    align-self: center;
}

.menu-drawer {
    top: 0;
    height: 100vh;
    z-index: 101 !important;
}

.hamburger-wrapper {
    padding: 1rem;
}

.menu-drawer .hamburger-wrapper {
    margin-left: auto;
    // z-index: 103 !important;
}


.hamburger-wrapper .hamburger::after {
    top: 0;
    // left: 0
}

.hamburger-wrapper .hamburger::before {
    top: 20px;
    // left: 0;
}

/* Class to be toggled on click */
.hamburger-wrapper .hamburger.active::before {
    top: 30px;
    // left: 30px;
}

.hamburger-wrapper .hamburger.active::after {
    top: -10px;
    // left: -30px;
}

@media screen and (max-width: 767px) {
    .sticky-try {
        min-height: 100%;
    }

    .tools {
        display: none !important;
    }

    .spenden-button {
        margin-left: 0.5rem;
    }

    .tools-wrapper {
        background-color: transparent;
        width: 50%;
    }
}



/* NAVIGATION */


#navigation {
    margin-left: auto;
    z-index: 5 !important;
}

#navigation .menu {
    margin-right: 2rem;
}

#navigation .item {
    padding: 1rem !important;
    margin: 0 !important;
    transition: background-color .22s ease, color .22s ease;
    color: #484b50 !important;
    border: none !important;
    // text-transform: initial;
    // font-size: 1rem;
}

#navigation .item:hover {
    background-color: var(--red) !important;
    color: white !important;
}

#navigation .computer>.nav-item>.item {
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
}


#navigation .item svg {
    height: 15px;
    width: 15px;
    margin-left: 0.5rem;
    margin-right: -0.5rem;
}

#navigation .nav-item {
    position: relative !important;
}

.active {
    border: none !important;
}

.secondnav .item {
    min-height: 64px;
    padding: 1rem;
}

.secondnav {
    position: absolute;
    min-width: 184px;
    transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
    /* Smooth transition */
    /*transform: translateY(-10px);
      /* Initial position above, will slide down */
    background-color: white;
}

.computer .secondnav {
    top: 100%;
}

/* ANIMATIONS */

.topoffset {
    animation: slideUp 0.3s ease;
    z-index: 10 !important;
}


.leftoffset {
    top: 0 !important;
    left: 100%;
    background-color: white;
    animation: slideLeft 0.3s ease;
    z-index: 3 !important;
}


@keyframes slideUp {
    from {
        transform: translateY(18px);
        opacity: 0;
    }

    to {
        transform: none;
        opacity: 1;
    }
}

@keyframes slideLeft {
    from {
        opacity: 0;
        transform: translateX(18px);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fade-in {
    animation: fadeIn 0.6s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.slide-down {
    animation: slide 0.6s ease-out;
}

@keyframes slide {
    from {
        margin-top: 100px;
        opacity: 0;
    }

    to {
        margin-top: 0;
        opacity: 1;
    }
}


/* BUTTONS ****************************************************************************************/
#ef-bl-x7jn2nd9j {
    z-index: 0 !important;
}


/* Apply styles to buttons except those inside .slate class divs */
.spenden-button button {
    cursor: pointer;
}

.spenden-button button,
.button_block button {
    background-color: var(--red) !important;
    padding: 0.5rem 1rem !important;
    color: white !important;
    font-weight: bold !important;
    transition: all 0.3s !important;
    border: 1px solid var(--red) !important;
}

.spenden-button:not(.disabled) button:hover,
.button_block a:not(.disabled) button:hover {
    color: var(--red) !important;
    background-color: white !important;
}

.button_block .bigger button {
    padding: 1rem 2rem !important;
}


#main .spenden-button {
    position: relative !important;
    inset: auto !important;
    display: inline !important;
    margin-right: 0.5rem;
}

.spenden-button span {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    background-size: cover;
    margin-left: 0.5rem;
    margin-bottom: -3px;
}

.blocks-group-wrapper>.block.__button,
.block.__button .button.container {
    display: flex;
    justify-content: center;
    align-content: center;
    max-width: var(--layout-container-width) !important;
}

.block.__button .button.container {
    margin: 1rem !important;
}

.disabled {
    opacity: 0.5 !important;
}

.disabled button {
    cursor: pointer !important;
}

.disabled.cookie-warning {
    text-align: center;
    display: flex;
    flex-direction: column;
}

.disabled.cookie-warning p {
    color: var(--red) !important;
    margin-top: 1rem;
}

.hidden {
    visibility: hidden !important;
}


/* PAGE ***************************************************************************************************************/

.ui.grid>.column:not(.row),
.ui.grid>.row>.column {
    padding: 1rem;
}

// #page-edit .full-width {
//     z-index: -1;
// }




.info-container {
    max-width: var(--layout-container-width);
    margin-right: auto;
    margin-left: auto;
}

.info-container .column-grid {
    margin: 0 -1rem !important;
}

.items .column-grid {
    margin: 0 -1rem !important;
}

#page-document .line {
    max-width: 100% !important;
}

.block.separator .line.dotted::after {
    border: none !important;
}

.block.separator .line.dotted {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='4' fill='%23ffffff' viewBox='0 0 4 4'%3E%3Ccircle r='2' cx='2' cy='2' fill='%23aa001e' /%3E%3C/svg%3E");
    background-position: top;
    // background-size: 14px 4px;
    background-repeat: repeat-x;
}

.block.separator .line.dotted {
    height: 5px;

}

.relative {
    position: relative;
}

.newspage h2 {
    margin-bottom: 0 !important;
}

.newspage h1 {
    margin-bottom: 2rem !important;
}

.newspage .datum {
    // color: var(--grey);
    font-size: 0.8rem;
}

@media screen and (max-width: 767px) {
    .newspage .datum {
        margin-left: 1rem;
    }

    .newspage .news_text {
        margin-left: 1rem;
    }

    .newspage .news_text img {
        margin-top: 2rem !important;
    }
}


.newspage .newsimage-wrapper {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;    
    width: 100% !important;

}

.newspage .block.image {
    margin-top: 0 !important;
}

.beschreibung {
    color: var(--grey);
    text-align: left;
    width: 100%;
    font-size: .9rem;
    display: inline-block !important;
    position: absolute
}

// .accordion-block .ui.styled.accordion {
//     border-top: 1px solid var(--red);
// }

.section-stiftungssatzung h2 {
    margin-top: 2rem;
    color: var(--red);
}

@media screen and (max-width: 767px) {

    #page-document .column-grid,
    #page-document .column-grid p {
        margin: 0 !important;
    }

    #page-document .column-grid img {
        width: 100%;
        // margin-bottom: 1rem;
    }

    #page-document .column-grid {
        padding: 1rem !important;
    }

    #page-document .HomepageNewsKacheln .column-grid {
        padding: 0 !important;
    }

    #page-document .column-grid .column-blocks-wrapper {
        padding: 0 0 1rem 0 !important;
    }
}

/* LISTING *****************************************************************************************/

.listing-container {
    transition: transform 0.3s ease;
    padding-top: 0 !important;
    padding-bottom: 2rem !important;
}

.listing-container:hover {
    transform: translateY(-5px);
}

.listing-container img {
    aspect-ratio: 16/9 !important;
    object-position: center !important;
    object-fit: cover;
    height: auto !important;
    width: 100% !important;
}

.listing-kachel {
    padding: 2rem;
    background-color: var(--grey) !important;
    height: 14rem !important;
}

.listing-kachel h3 {
    margin-left: 0 !important;
}

.listing-kachel .datum {
    display: flex;
}

.listing-kachel .datum time {
    margin-left: auto;
}

@media screen and (max-width: 1490px) {
    .listing-kachel {
        height: auto !important;
    }
}

.pdf-list a {
    display: block;
}

.pdf-list h2 {
    margin-bottom: 0 !important;
}

@media screen and (max-width: 767px) {
    .pdf-list {
        padding: 1rem;
        text-align: center;
    }

    .pdf-list .react-pdf__Page {
        display: flex;
        justify-content: center;
    }

}

.pdf-list .react-pdf__Page__canvas {
    height: 150px !important;
    width: auto !important;
}

/* PDF **********************************************************************************************/

// .react-pdf__Document {
//     margin-top: 2rem;
// }

.pdf-controls {
    margin: 1rem 0;
    display: flex;
    align-items: center;
}

.pdf-controls .pageNum {
    margin: 0 1rem;
}


.pdf-button {
    width: 28px;
    height: 28px;
}

.pdf-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
}

.pdf-button.disabled {
    opacity: 0.5;
    /* or any other styling to indicate disabled state */
}

.pdf-button .icon {
    display: inline-block;
    width: 30px;
    /* Adjust width and height as needed */
    height: 30px;
    background-size: cover;
}

.download-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-size: cover;
    margin-right: 1rem;
}

@media screen and (max-width: 1490px) {
    .contenttype-file .content-area {
        padding: 1rem !important;
        text-align: center;
    }

    .pdf-controls,
    .react-pdf__Page {
        display: flex;
        justify-content: center !important;
    }

    .contenttype-file h1 {
        margin: 0 0 2rem 0 !important;
    }
}


/* FOOTER *****************************************************************************************************/

.footer-container {
    max-width: var(--layout-container-width) !important;
    margin-left: auto;
    margin-right: auto;
}

.footer-container>.segment {
    padding: 0 !important;
}

.footer-container .column-blocks-wrapper {
    text-align: left !important;
}

#footer {
    background-color: var(--darkgrey) !important;
    padding: 2rem 0;
}

#footer .segment {
    background-color: var(--darkgrey) !important;
}

#footer a {
    color: white;
    display: inline-block;
    width: 100%;
    transition: all 0.3s;
}

#footer a:hover {
    color: var(--red) !important;
}

#footer span {
    display: inline-block;
    height: 23px;
    width: 23px;
    background-size: cover;
    margin-right: 1rem;
}

#footer .icon-section {
    display: flex;
    border-bottom: 1px solid white;
    padding-bottom: 1rem;
}

#footer .icon-section.spaced {
    margin-bottom: 1rem;
}

#footer .column-grid {
    margin: 0 -1rem !important;
}

#footer .hint {
    font-size: 10px;
}

#footer input {
    margin-bottom: 1rem;
}

#footer button {
    display: none;
}

#footer button.cookie-button {
    color: white;
    display: inline-block;
    width: 100%;
    transition: all 0.3s;
    background-color: transparent;
    box-shadow: none;
    border: none;
    text-align: left;
    padding: 0;
    font-family: var(--custom-main-font, "Inter", sans-serif);
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
}

#footer button.cookie-button:hover {
    color: var(--red);
    cursor: pointer;
}

#footer .searchbox svg {
    height: 1rem !important;
}

@media screen and (max-width: 1490px) {
    #footer {
        padding: 1rem;
    }

    #footer .ui.container {
        margin: 0 !important;
    }
}

.footer-news a:not(:last-child) {
    margin-bottom: calc(0.5rem);
}