// PT Serif font family
@font-face {
    font-family: 'PT Serif';
    font-style: normal; // regular
    font-weight: 400;
    src: local('PT Serif');
    src: url('./fonts/PT-Serif-Regular.ttf');
    src: url('./fonts/PT-Serif-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PT Serif';
    font-style: italic;
    font-weight: 400;
    src: local('PT Serif');
    src: url('./fonts/PT-Serif-Regular-Italic.ttf');
    src: url('./fonts/PT-Serif-Regular-Italic.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 700;
    src: local('PT Serif');
    src: url('./fonts/PT-Serif-Bold.ttf');
    src: url('./fonts/PT-Serif-Bold.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PT Serif';
    font-style: italic;
    font-weight: 700;
    src: local('PT Serif');
    src: url('./fonts/PT-Serif-Bold-Italic.ttf');
    src: url('./fonts/PT-Serif-Bold-Italic.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PT Serif Caption';
    font-style: normal;
    font-weight: 400;
    src: local('PT Serif Caption');
    src: url('./fonts/PTSerifCaption-Regular.ttf');
    src: url('./fonts/PTSerifCaption-Regular.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PT Serif Caption';
    font-style: italic;
    font-weight: 400;
    src: local('PT Serif Caption');
    src: url('./fonts/PTSerifCaption-Italic.ttf');
    src: url('./fonts/PTSerifCaption-Italic.ttf') format('truetype');
  }
  