.info-container {
    max-width: var(--layout-container-width);
    margin-right: auto;
    margin-left: auto;
    padding: 3rem 0 4rem 0;
}

.info-container .ui.grid.column-grid {
    margin: 0;
}

.info-container .column-blocks-wrapper {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

@media screen and (max-width: 767px) {
    .info-container {
        padding: 0;
    }

    .info-container .teaser-description-title {
        padding: 1rem 0 0 0 !important;
    }
    #page-document .info-container .left .column-blocks-wrapper{
        padding-bottom: 0 !important;
    }
    .info-container .left .teaser-description-title {
        padding-top: 2rem !important;
    }
    #page-document .info-container .left {
        padding-bottom: 0 !important;
    }
    #page-document .info-container .left img {
        margin: 2rem 0 !important;
    }

    .info-container h2 {
        margin-bottom: 0 !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1500px) {
    .info-container {
        padding: 3rem 1rem;
        h2 {
            margin-left: 0 !important;
        }
    }
}

.info-button-container {
    width: 100%;
    text-align: left;
}

.info-container .button {
    padding: 8px 20px;
    // border: 1px solid #000;
    border-radius: unset;
    background: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-top: 2rem;
    transition: all 0.3s;
}

.red .info-container .button,
.darkgrey .info-container .button {
    background-color: white !important;
    color: var(--red);
    border: 1px solid white;
}

.red .info-container .button:hover,
.darkgrey .info-container .button:hover {
    background-color: transparent !important;
    color: white !important;
    border: 1px white solid;
}


.white .info-container .button,
.beige .info-container .button,
.grey .info-container .button {
    background-color: var(--red) !important;
    color: white;
    border: 1px solid var(--red);
}

.white .info-container .button:hover,
.beige .info-container .button:hover,
.grey .info-container .button:hover {
    background-color: transparent !important;
    color: var(--red) !important;
    border: 1px var(--red) solid;
}

// .beige .info-container .button {
//     background-color: white !important;
//     color: black;
//     border: 1px solid var(--beige);
// }

// .beige .info-container .button:hover {
//     background-color: var(--beige) !important;
//     color: white !important;
//     border: 1px white solid;
// }


// .white .info-container .button {
//     background-color: black !important;
//     color: white;
//     border: 1px solid black;
// }

// .white .info-container .button:hover {
//     background-color: white !important;
//     color: black !important;
//     border: 1px black solid;
// }

// .grey .info-container .button {
//     background-color: white !important;
//     color: black;
//     border: 1px solid var(--grey);
// }

// .grey .info-container .button:hover {
//     background-color: var(--grey) !important;
//     color: white !important;
//     border: 1px white solid;
// }

// .grey .info-container .button {
//     background-color: var(--red) !important;
//     color: white;
//     border: 1px solid var(--red);
// }

// .grey .info-container .button:hover {
//     background-color: var(--grey) !important;
//     color: var(--red) !important;
//     border: 1px solid var(--red);
// }

// .darkgrey .info-container .button {
//     background-color: white !important;
//     color: black;
//     border: 1px solid var(--darkgrey);
// }

// .darkgrey .info-container .button:hover {
//     background-color: var(--darkgrey) !important;
//     color: white !important;
//     border: 1px white solid;
// }