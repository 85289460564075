.blockquote {
    padding: 1rem !important;

}

.blockquote-container {
    max-width: var(--layout-container-width) !important;
}

.blockquote-caption {
    font-size: .875rem;
    color: #bbb8b8;
    line-height: 1.57;
    margin: 0 0 0 1.5rem;
}

@media screen and (max-width: 767px) {
    .blockquote {
        border-left: none !important;
        border-right: none !important;
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }

    
    .blockquote-caption {
        margin: 2rem 0 2rem 0 !important;
        text-align: center;
    }
}

@media screen and (max-width: 1490px) {
    .blockquote-container {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

}


@media screen and (min-width: 768px) {
    .blockquote {
        border-top: none !important;
        border-right: none !important;
        border-bottom: none !important;
    }
}

.blockquote h2, .blockquote h3 {
    margin-bottom: 0 !important;
}