.info-kacheln-wrapper .column-grid {
    margin: 0 -1rem !important;
}

.info-kacheln-wrapper {
    margin-bottom: 3rem;
}

.info-kacheln {
    padding: 1rem !important;
}

.info-kacheln .tile {
    background-color: var(--grey);
    padding: 2rem;
    transition: transform 0.3s ease;
}

.info-kacheln .tile:hover {
    transform: translateY(-5px);
}

.info-kacheln .tile img{
    margin-bottom: 1rem;
}

.info-kacheln h3 {
    color: var(--red);
}
